import { keyframes, css } from 'styled-components';

const rules = {
  fadeIn: keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }`,
  fadeInLeft: keyframes`
    from {
      opacity: 0;
      transform: translateX(-50%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }`,
  fadeInRight: keyframes`
    from {
      opacity: 0;
      transform: translateX(50%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }`,
  fadeOutRight: keyframes`
    from {
      opacity: 1;
      transform: translateX(0);
    }
    to {
      opacity: 0;
      transform: translateX(50%);
    }`,
  blink: keyframes`
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }`,
  spinner: keyframes`
    0%, 39%, 100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }`,
  spin: keyframes`
    100% {
      transform: rotate(360deg);
    }`,
};

const fadeIn = css`
  animation: ${rules.fadeIn} 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
`;
const fadeInLeft = css`
  animation: ${rules.fadeInLeft} 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
`;
const fadeInRight = css`
  animation: ${rules.fadeInRight} 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
`;
const fadeOutRight = css`
  animation: ${rules.fadeOutRight} 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
`;
const blink = css`
  animation: ${rules.blink} 1s infinite ease-in-out both;
`;
const spinner = css`
  animation: ${rules.spinner} 1.2s infinite ease-in-out both;
`;
const spin = css`
  animation: ${rules.spin} 2s infinite linear;
`;

export { fadeIn, fadeInLeft, fadeInRight, fadeOutRight, blink, spin, spinner };
